import React from 'react';
import Header from  '../components/Header/header'
import {Layout,Tabs} from "antd";
import FormPanel from '../components/FormPanel/PersonPanel';
import TextPanel from '../components/FormPanel/TextPanel';
import DeletePanel from '../components/FormPanel/DeletePanel';
import {useDispatch,connect} from "react-redux";
import {getConfig} from '../components/util/redux/asyncActions'
import {useNavigate} from 'react-router'
import ManyosSpinner from '../components/ManyosSpinner/ManyosSpinner'

const {TabPane} = Tabs;
const {Content} = Layout;

const select = state => {

    return {
        config: state.request.config,

        loading:state.request.loading,
        fields:state.request.fields

    };
};


const ConfigView = (props)=>{

    const {config,loading,fields}=props;
    const dispatch=useDispatch();
    const navigate=useNavigate();



    React.useEffect(()=>{
       if (config.length==0){
           console.info("calling config!")
           dispatch(getConfig({navigate}));
       }
    },[])





    return (
        <>

            <Header>
                <Content >
                    <ManyosSpinner spinning={loading}>

                    <Tabs style={{ width:"100%"}}>
                        <TabPane
                            tab={"Persons"}
                            key={"pdata"}
                        >


                                {
                                    config && Object.keys(config).length >0 && config[0].personConfig &&

                                    config[0].personConfig.map((c,i)=>(
                                        <FormPanel
                                            key={'person-'+i}
                                            name={c.form}
                                            query={c.query}
                                            allFields={fields}
                                            fields={fields && fields.find(e=>e.form===c.form).fields}
                                            relatedRecords={c.relatedRecords}
                                            dataSource={
                                                Object.keys(c.fields).map(field=>{
                                                    return {
                                                        field,...c.fields[field]
                                                    }
                                                })
                                               }
                                            fcolumns={
                                               [
                                                   {
                                                       title: 'Field',
                                                       dataIndex: 'field',
                                                       key: 'field',

                                                   },
                                                   {
                                                       title: 'Action',
                                                       dataIndex: 'action',
                                                       key: 'action',

                                                   },
                                                   {
                                                       title: 'Size',
                                                       dataIndex: 'size',
                                                       key: 'size',

                                                    },
                                                   {
                                                       title: 'Person Attribute',
                                                       dataIndex: 'personAttribute',
                                                       key: 'personAttribute',

                                                   },

                                                   {
                                                       title: 'Value',
                                                       dataIndex: 'value',
                                                       key: 'value',

                                                   }
                                               ]




                                            }
                                        />
                                    ))

                            }



                        </TabPane>
                        <TabPane
                            tab={"anonymize Text"}
                            key={"text"}
                        >
                            {
                                config && Object.keys(config).length >0 && config[1].textConfig &&

                                config[1].textConfig.map(c=>(
                                    <TextPanel
                                        name={c.form}
                                        query={c.query}
                                        allFields={fields}
                                        fields={fields && fields.find(e=>e.form===c.form).fields}

                                        dataSource={
                                            Object.keys(c.fields).map(field=>{
                                                return {
                                                    field,...c.fields[field]
                                                }
                                            })
                                        }
                                        fcolumns={
                                            [
                                                {
                                                    title: 'Field',
                                                    dataIndex: 'field',
                                                    key: 'field',

                                                },
                                                {
                                                    title: 'Action',
                                                    dataIndex: 'action',
                                                    key: 'action',

                                                },
                                                {
                                                    title: 'Size',
                                                    dataIndex: 'size',
                                                    key: 'size',

                                                },
                                                {
                                                    title: 'Value',
                                                    dataIndex: 'value',
                                                    key: 'value',

                                                }
                                            ]




                                        }
                                    />
                                ))

                            }
                        </TabPane>
                        <TabPane
                            tab={"delete Records"}
                            key={"delete"}
                        >

                            {
                                config && Object.keys(config).length >0 && config[2].deleteConfig &&

                                config[2].deleteConfig.map((c,i)=>(
                                    <DeletePanel
                                        key={`deletepanel-${i}`}
                                        index={i}
                                        name={c.form}
                                        query={c.query}
                                        allFields={fields}
                                        description={c.description}

                                    />
                                ))

                            }
                        </TabPane>
                    </Tabs>
                    </ManyosSpinner>
                </Content>
            </Header>


        </>

    )
};

export default connect(select)(ConfigView);
