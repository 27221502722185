import React from 'react';
import Editor,{useMonaco} from "@monaco-editor/react";
import {useDispatch,connect} from "react-redux";
import {Layout} from "antd";
import ManyosSpinner from '../components/ManyosSpinner/ManyosSpinner'
import {setUnsaved} from '../components/util/redux/index'

const {Content}=Layout

const select = state => {

    return {
        config: state.request.config,

        loading:state.request.loading,
        fields:state.request.fields

    };
};


const EditorView = (props)=>{

    const {loading,config,fields,onChange}=props;

    const dispatch=useDispatch();





    return (
        <Content>
        {

                (config && config.length > 0) && (
                    <Editor
                        defaultValue={config && JSON.stringify(config,0,2)}


                        key={"app.config"}
                        defaultLanguage={"json"}
                        onChange={(code)=>onChange(code)}
                        height={"100vh"}

                    />
                )
        }
        </Content>
)



}

export default connect(select)(EditorView);
