import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import {Collapse,Table,Space,Button} from "antd";
import RemedyEditor from '../util/monaco-remedy'

import {PlusOutlined  } from '@ant-design/icons';
import AddRR from '../Drawers/AddRR'

const {Panel} = Collapse;


const PersonPanel = (props)=> {

    const {name,fields,dataSource,fcolumns,query,relatedRecords,allFields}=props;

    const [state,setState]=React.useState({showAddRR:false})


    const visibleAddRR=(open)=>{
        setState({...state,showAddRR:open})
    }
    const columns = [
        ...fcolumns,
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <a>Delete</a>
                </Space>
            ),
        },
    ];
    const genExtra = () => (
        <DeleteOutlined
            onClick={(event) => {
                // If you don't want click extra trigger collapse, you can prevent this:
                event.stopPropagation();
            }}
        />
    );

    return (
        <Collapse defaultActiveKey={['1']}>
            <Panel key={1} header={name} extra={genExtra()}>

                <RemedyEditor
                    key={"person-bq-editor"}
                    minimap={ {
                        enabled:false
                    }}
                    height={"7vh"}
                    index={"person-bq-editor"}
                    defaultValue={query }
                    formFields={fields}
                    form={name}
                    onChange={(v)=>console.log(v)}
                    //setConfig(JSON.parse(config))}
                 />
                <Table
                    title={()=>"Person Settings"}
                    key={"Person "}
                    dataSource={dataSource} columns={columns}
                />
                <div className={"p-add-rr"}>
                <Button  icon={<PlusOutlined />} size={"large"} type="dashed"  onClick={(e)=>{
                    visibleAddRR(true)
                }}>

                    add related record
                </Button>
                </div>
                {
                    relatedRecords && relatedRecords.length>0 &&
                    relatedRecords.map((rr,i)=>(
                        <Collapse defaultActiveKey={['rr-0']}  key={"Collapse-person-rr-"+i} className={"p-rr-collapse"}>
                            <Panel key={`rr-${i}`} header={<div><span>{rr.form}</span><span className={"p-rr-description"}>{rr.description}</span></div>} extra={genExtra()}>
                                <RemedyEditor
                                    key={"rr"+i+"-bq-editor"}
                                    height={"7vh"}
                                    index={"rr"+i+"-bq-editor"}
                                    defaultValue={rr.query }
                                    formFields={allFields && allFields.find(af=>af.form===rr.form) && allFields.find(af=>af.form===rr.form).fields}
                                    form={rr.form}
                                    onChange={(v)=>console.log(v)}
                                    minimap={ {
                                        enabled:false
                                    }}
                                    //setConfig(JSON.parse(config))}
                                />

                                <Table

                                    title={()=>"Attribute Mapping"}
                                    dataSource={

                                            Object.keys(rr.fields).map(field=>{
                                                return {
                                                    field,attribute:rr.fields[field]
                                                }
                                            })

                                    } columns={

                                    [
                                        {
                                            title: 'Field',
                                            dataIndex: 'field',
                                            key: 'field',

                                        },
                                        {
                                            title: 'Attribute',
                                            dataIndex: 'attribute',
                                            key: 'attribute',

                                        },
                                     ]}
                                    />
                                <Table
                                    title={()=>"Constants"}
                                    dataSource={

                                        Object.keys(rr.constants).map(field=>{
                                            return {
                                                field,value:rr.constants[field]
                                            }
                                        })

                                    } columns={

                                    [
                                        {
                                            title: 'Field',
                                            dataIndex: 'field',
                                            key: 'field',

                                        },
                                        {
                                            title: 'Value',
                                            dataIndex: 'value',
                                            key: 'value',

                                        },
                                    ]}
                                />
                            </Panel>
                        </Collapse>
                    ))
                }


            </Panel>
            <AddRR
                open={state.showAddRR}
                onClose={(v)=>{visibleAddRR(false)}}
            ></AddRR>
        </Collapse>

    )
}

export default PersonPanel;
