
import React, {useContext} from 'react';

import {Space,Button,Drawer} from 'antd'

const AddRR =({open,onClose})=>{

    return(
        <Drawer
            title="Add related record"
            width={720}
            onClose={onClose}
            open={open}
            bodyStyle={{
                paddingBottom: 80,
            }}
            extra={
                <Space>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button onClick={onClose} type="primary">
                        Add
                    </Button>
                </Space>
            }
        >
            <div>
                content
            </div>
        </Drawer>
    )

}

export default AddRR;
