import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import {Collapse,Table,Space} from "antd";
import RemedyEditor from '../util/monaco-remedy'



const {Panel} = Collapse;


const DeletePanel = (props)=> {

    const {name,description,query,allFields,index,key}=props;

    console.log(allFields)

    const genExtra = () => (
        <DeleteOutlined
            onClick={(event) => {
                // If you don't want click extra trigger collapse, you can prevent this:
                event.stopPropagation();
            }}
        />
    );

    return (
        <Collapse defaultActiveKey={['1']}>
            <Panel key={1}  header={<div><span>{name}</span><span className={"p-rr-description"}>{description}</span></div>} extra={genExtra()}>
                <div>Query</div>

                <RemedyEditor
                    key={`delete-bq-editor-${index}`}
                    height={"7vh"}
                    minimap={ {
                        enabled:false
                    }}
                    defaultValue={query }
                    formFields={allFields && allFields.find(af=>af.form===name) && allFields.find(af=>af.form===name).fields}
                    form={name}
                    onChange={(v)=>console.log(v)}
                    //setConfig(JSON.parse(config))}
                 />






            </Panel>
        </Collapse>
    )
}

export default DeletePanel;
