
 import React, {useContext} from 'react';


const PageHeader = ({extra,className,title,children})=>{


return(

    <div className={className}>
        <div className={"mys-pageheader-title"}>
            {title}
        </div>
        <div className={"mys-pageheader-extra"}>
            {extra}
        </div>
        <div className={"mys-pageheader-content"}>
            {children}
        </div>

    </div>
)
}
export default PageHeader
