import {
    configureStore,
    getDefaultMiddleware,
    createSlice,

} from "@reduxjs/toolkit";



import {getConfig,setConfig} from "./asyncActions";

const middleware = [
    ...getDefaultMiddleware(),
    /*YOUR CUSTOM MIDDLEWARES HERE*/
];




const requestState = {
    config:[],
    loading:false,
    unsaved:false,
    dismissed:false,
    codeView:false,
    fields:[]
};



const requestSlice = createSlice({
    name: "request",
    initialState: requestState,
    reducers: {

        resetState:(state)=> {
            return state = {
                config:[],
                loading:false,
                unsaved:false,
                dismissed:false,
                codeView:false,
                fields:[]
            };

        },

        dismissSave:(state,action)=>{
            state.unsaved=false;
            state.dismissed=true;
            return state;
        },
        setCodeView:(state,action)=>{

            state.codeView=action.payload.codeView;
            return state;
        },
        setUnsaved:(state,action)=>{

            state.unsaved=action.payload;
            return state;
        }


    },
    extraReducers:{
        [getConfig.pending]: state => {
            state.loading = true;
        },
        [getConfig.rejected]: (state, action) => {
            state.loading = false;

            state.error = action.error.message;
        },
        [getConfig.fulfilled]: (state, action) => {
            state.loading = false;
            state.config = action.payload.response;
            state.fields=action.payload.fields;

        },
        [setConfig.pending]: state => {
            state.loading = true;
        },
        [setConfig.rejected]: (state, action) => {
            state.loading = false;

            state.error = action.error.message;
        },
        [setConfig.fulfilled]: (state, action) => {
            state.loading = false;
            state.unsaved = false;
            state.config = action.payload.response;
            state.fields=action.payload.fields;

        }
    }
});

export const { dismissSave,setCodeView,setUnsaved} = requestSlice.actions;

const requestReducer = requestSlice.reducer;

export default configureStore({
    reducer: {
        request: requestReducer,
    },
    middleware,
});



