import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import {Collapse,Table,Space} from "antd";
import RemedyEditor from '../util/monaco-remedy'



const {Panel} = Collapse;


const TextPanel = (props)=> {

    const {name,fields,dataSource,fcolumns,query,relatedRecords,allFields}=props;
    console.log(fields);
    const columns = [
        ...fcolumns,
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <a>Delete</a>
                </Space>
            ),
        },
    ];
    const genExtra = () => (
        <DeleteOutlined
            onClick={(event) => {
                // If you don't want click extra trigger collapse, you can prevent this:
                event.stopPropagation();
            }}
        />
    );

    return (
        <Collapse defaultActiveKey={['1']}>
            <Panel key={1} header={name} extra={genExtra()}>

                <RemedyEditor
                    key={"person-bq-editor"}
                    height={"7vh"}
                    minimap={ {
                        enabled:false
                    }}
                    defaultValue={query }
                    formFields={fields}
                    form={name}
                    onChange={(v)=>console.log(v)}
                    //setConfig(JSON.parse(config))}
                 />
                <Table
                    dataSource={dataSource} columns={columns}
                />




            </Panel>
        </Collapse>
    )
}

export default TextPanel;
