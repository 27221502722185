import {
    createAsyncThunk
} from "@reduxjs/toolkit";




/*

export function smileConnectAPIcall({ url,requestOptions,userManager,navigate }){




    return userManager.getUser().then(user=>{
            if (user){
                if (requestOptions.hasOwnProperty("headers")){
                    requestOptions.headers.Authorization =  "Bearer " + user[window._env_.REACT_APP_OIDC_TOKEN]
                }else{
                    requestOptions.headers={"Authorization":  "Bearer " + user[window._env_.REACT_APP_OIDC_TOKEN]}
                }
            }

           return fetch(url, requestOptions)
                    .then(async data => {

                            if (data.status && data.status>400){
                                let error = await data.json();
                                navigate(navigate.location.pathname, {
                                    replace:true,
                                    errorStatusCode: data.status,
                                    errorDetail: JSON.stringify({url,...error})
                                });
                            }else{

                                return data.json()
                            }

                        }).catch(error=>{

                   navigate(navigate.location.pathname, {
                       replace:true,
                       errorStatusCode: '500',
                       errorDetail: JSON.stringify({url,error:""+ error})
                   })})

                    .then(({ status, ...apiData}) => {

                        if ( ({...apiData}.hasOwnProperty("error") && {...apiData}.hasOwnProperty("stackTrace"))) {

                            const error = {...apiData};
                            navigate(navigate.location.pathname, {
                                replace:true,
                                errorStatusCode: 500,
                                errorDetail: JSON.stringify({url,...error})
                            });
                           throw Error(JSON.stringify(error))
                        } else {

                            return apiData ;
                        }
                    }).catch(error => {throw Error(error)});
        })


}
*/

 function smileanonymizeAPIcall({url,requestOptions}){


    return fetch(url, requestOptions)
        .then(async data => {
                return data.json()
        })
        .then(({ ...apiData}) => {



                return apiData ;

        }).catch(e => {console.error("did not work"); return {}})

}

const getFormFields=async (config,fields)=>{
        let addFields=[];
        const wait= await Promise.all(config.map(async pC=>{



        //fields=[{form:"CTM:People",fields:[...]}]
        //pC.form="CTM:People"

        if (!(fields.find(list=>list.form===pC.form))){

            const newFields =await smileanonymizeAPIcall({url:`${window._env_.REACT_APP_API_URL}/v1/config/forms/${pC.form}/fields`,requestOptions:{method:"GET","content-type":"application/json"}})
            addFields.push({form:pC.form,fields:newFields.data})




        }
        if (pC.relatedRecords){
            await Promise.all(pC.relatedRecords.map(async rC=>{


                if (!(fields.find(list=>list.form===rC.form))){
                    const newFields =await smileanonymizeAPIcall({url:`${window._env_.REACT_APP_API_URL}/v1/config/forms/${rC.form}/fields`,requestOptions:{method:"GET","content-type":"application/json"}})
                    addFields.push({form:rC.form,fields:newFields.data})

                }
            }));
        }







    }));
    return addFields

}

export const getConfig = createAsyncThunk("request/getConfig",  async ({},thunkAPI) => {


    const response = await smileanonymizeAPIcall({url:`${window._env_.REACT_APP_API_URL}/v1/config`,requestOptions:{method:"GET",headers:{"content-type":"application/json"}}});

    const parsedResponse = Object.keys(response).map(k=>(response[k]))
    let fields=[...thunkAPI.getState().request.fields]

    let addPeopleFields=[];
    let addTextFields=[];
    let addDeleteFields=[];


    if (response[0].personConfig && Array.isArray(response[0].personConfig) ){
        addPeopleFields=await getFormFields(response[0].personConfig,fields)
    }
    if (response[1].textConfig && Array.isArray(response[1].textConfig) ){
        addTextFields=await getFormFields(response[1].textConfig,fields)
    }
    if (response[2].deleteConfig && Array.isArray(response[2].deleteConfig) ){
        addDeleteFields=await getFormFields(response[2].deleteConfig,fields)
    }
    console.log("hmm")
    return {response:parsedResponse,fields:[...fields,...addPeopleFields,...addTextFields,...addDeleteFields]};
});

export const getFields = createAsyncThunk("request/setConfig",  async () => {

    const response = await smileanonymizeAPIcall({url:`${window._env_.REACT_APP_API_URL}/v1/config`,requestOptions:{method:"GET",headers:{"content-type":"application/json"}}});

    return response;
});
export const getForms = createAsyncThunk("request/setConfig",  async () => {

    const response = await smileanonymizeAPIcall({url:`${window._env_.REACT_APP_API_URL}/v1/config`,requestOptions:{method:"GET",headers:{"content-type":"application/json"}}});

    return response;
});
export const setConfig = createAsyncThunk("request/setConfig",  async ({config},thunkAPI) => {

    const response = await smileanonymizeAPIcall({url:`${window._env_.REACT_APP_API_URL}/v1/config`,requestOptions:{method:"PUT",headers:{"content-type":"application/json"},body:JSON.stringify(config,0,2)}});
    const parsedResponse = Object.keys(response).map(k=>(response[k]))
    let fields=[...thunkAPI.getState().request.fields]

    let addPeopleFields=[];
    let addTextFields=[];
    let addDeleteFields=[];


    if (response[0].personConfig && Array.isArray(response[0].personConfig) ){
        addPeopleFields=await getFormFields(response[0].personConfig,fields)
    }
    if (response[1].textConfig && Array.isArray(response[1].textConfig) ){
        addTextFields=await getFormFields(response[1].textConfig,fields)
    }
    if (response[2].deleteConfig && Array.isArray(response[2].deleteConfig) ){
        addDeleteFields=await getFormFields(response[2].deleteConfig,fields)
    }

    return {response:parsedResponse,fields:[...fields,...addPeopleFields,...addTextFields,...addDeleteFields]};

});

