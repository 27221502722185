import React from "react";
import Editor,{useMonaco} from "@monaco-editor/react";


export default function RemedyEditor(props){

    const {formFields,config,form,index,minimap}=props;




    function handleEditorWillMount(monaco){

        if ( monaco && monaco.languages.getLanguages().filter(l=>l.id=="SC_"+form).length==0){



            monaco.languages.register({ id: "SC_"+form });
            monaco.languages.setMonarchTokensProvider("SC_"+form, {
                tokenizer: {
                    root: [
                        [/'.*?'/, "field-name"],
                        [/\".*?\"/, "field-value"],
                        [/\$.*?\$/, "keyword"],
                        [/\[info.*/, "custom-info"],
                        [/\[[a-zA-Z 0-9:]+\]/, "custom-date"],
                    ]
                }
            });


            monaco.languages.registerCompletionItemProvider("SC_"+form, {
                provideCompletionItems: (model,position) => {

                    const word = model.getWordUntilPosition(position);
                    const range = {
                        startLineNumber: position.lineNumber,
                        endLineNumber: position.lineNumber,
                        startColumn: word.startColumn-1,
                        endColumn: word.startColumn
                    };

                    const range2 = {
                        startLineNumber: position.lineNumber,
                        endLineNumber: position.lineNumber,
                        startColumn: word.endColumn+1,
                        endColumn: word.endColumn+2
                    };
                    const range3 = {
                        startLineNumber: position.lineNumber,
                        endLineNumber: position.lineNumber,
                        startColumn: word.startColumn-1,
                        endColumn: word.startColumn
                    };
                    const check=model.getValueInRange(range);
                    const check2=model.getValueInRange(range2);
                    const check3=model.getValueInRange(range3);

                    let sugFields=[];

                    if (check != '"' && check2 != '"'){
                        sugFields=formFields.map(f=>{return {

                            label: f,
                            kind: monaco.languages.CompletionItemKind.Field,
                            insertText: (check3=="'")?f+'\'="$0"':'\''+f+'\'="$0"',
                            insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,


                        }});
                    }

                    const suggest=[
                        ...sugFields,
                        {
                            label: 'OR',
                            kind: monaco.languages.CompletionItemKind.Keyword,
                            insertText: ' OR ($0)',
                            insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet
                        },
                        {
                            label: 'AND',
                            kind: monaco.languages.CompletionItemKind.Keyword,
                            insertText: ' AND ($0)',
                            insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                        }];


                    return {suggestions: suggest};
                }
            });
            monaco.editor.defineTheme('myCoolTheme', {
                base: 'vs',
                inherit: false,
                rules: [
                    { token: 'custom-info', foreground: '808080' },
                    { token: 'field-name', foreground: 'ff0000', fontStyle: 'bold' },
                    { token: 'field-value', foreground: 'FFA500' },
                    { token: 'keyword', foreground: '008800' },
                ],
                colors: {
                    'editor.foreground': '#000000',
                    'editor.background': '#FFFFFF',
                    'editorCursor.foreground': '#b8b8b8'

                }
            });


        }
    }

    function handleEditorDidMount(editor,monaco){

        monaco.editor.setTheme('myCoolTheme');
    }


if (formFields){
    return (


        <Editor
            options={{minimap}}
            path={index}
            defaultLanguage={"SC_"+form}
            onMount={handleEditorDidMount}
            beforeMount={handleEditorWillMount}
            {...props}
        />)
}else{
    return null
}




}




