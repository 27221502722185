import React, {useContext} from 'react';

import {Tabs,Switch,Dropdown,Button,Menu,message,Alert,Modal,Table} from 'antd';

import { LogoutOutlined,DownOutlined,SaveOutlined } from '@ant-design/icons';

import {useDispatch,connect} from "react-redux";
import {setCodeView,setUnsaved} from '../../components/util/redux/index';
import {setConfig} from '../../components/util/redux/asyncActions'
import EditorView from '../../views/editor'
import PageHeader from './PageHeader'

const select = state => {

    return {
        loading: state.request.loading,
        codeView:state.request.codeView,
        unsaved:state.request.unsaved,
        dismissed:state.request.dismissed,
        config:state.request.config


    };
};



const Header=(props) =>{

    const {  codeView,loading,children,config,unsaved,dismissed} = props;


    const dispatch=useDispatch();

    const [newConf,setNewConf] = React.useState();

    const handleChange =  event => {

        dispatch(setCodeView({codeView:event}))
    };


    const prevUnsavedRef = React.useRef();
    React.useEffect(() => {
        prevUnsavedRef.current = unsaved;
    });

    const prevUnsaved = prevUnsavedRef.current;


    React.useEffect(()=>{


        if (!unsaved && prevUnsaved){
            message.success("Saved successfully!");
        }

    },[unsaved])


    const onChange=(code)=>{
        try{

            setNewConf(JSON.parse(code))
            dispatch(setUnsaved(true));

        }catch(e){
            console.error(e)
        }

    }

    return (
        <>


            <PageHeader
                className={"Header"}

                title={
                    <>
                        <span className={"title"}>SMILEconceal</span>


                    </>
                }

                extra={[
                    <div key="1" style={{display:"inline-block",color:"white", marginRight:"10px"}}>{"Code View " }<Switch checked={codeView} onChange={handleChange}/></div>,
                    <Button  icon={<SaveOutlined />}  loading={loading}
                             ghost={true}
                             onClick={() =>
                             {
                                    dispatch(setConfig({config:newConf}))

                             }
                             } disabled={!unsaved} style={{marginRight:"10px"}}>Save</Button>,
                    <Button key="2" href="https://smileanonymize.manyosdocs.de" target="new"  ghost={true}>Docs</Button>,



                ]}
            >
                { (!codeView)
                    ? ( children)
                    :(<EditorView onChange={onChange}/>)
                }

            </PageHeader>
        </>







    );
}

export default connect(select)(Header);


