
import React from 'react';
import ReactDOM from 'react-dom';
//import 'antd/dist/antd.css';
import './assets/css/anonymizer.css'
import {ConfigProvider} from 'antd'

import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {Provider} from "react-redux";
import store from "./components/util/redux/index";
import ConfigView from "./views/config.js";




ReactDOM.render(

    <Provider store={store}>
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#ef7721',
                },
            }}
        >

            <BrowserRouter>
                <Routes>

                    <Route path="/" element={<ConfigView />}/>


                </Routes>

            </BrowserRouter>

            </ConfigProvider>
    </Provider>


    , document.getElementById('root'));


